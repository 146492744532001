
<template>
 <div >   
  <audio   id="bgmusic"  :src="this.musics" autoplay="autoplay"  :muted="this.muted"  loop="loop" >
  </audio>
    <!-- <audio loop="loop" preload="auto" ref="audio"  muted id="audio" autoplay="autoplay" :src="this.musics" > -->
      <!-- <source   type="mp3"> -->
      <!-- <source src="hangge.ogg" type="audio/ogg"> -->
    <!-- </audio> -->
    
  <!-- <mini-audio 
    audio-source="./static/music/晴天.mp3"
  ></mini-audio> -->


   <!-- <audio id="audio" ref="audio" :src="require('./static/music/晴天.mp3')"  autoplay="autoplay" :hidden="true"></audio> -->
  </div>

  <nav>
    <router-link  @click="love_image_true"  to="/">
          
         <!-- <img :src="this.imgUrl" id="image_icon" aria-placeholder="dianji" > -->
         

    </router-link> 

  </nav>
<div id="image_icon" >
            <div style="float:left;width: 20%"  click="this.love_image_true">
                <!-- <el-button type="primary" icon="el-icon-s-home" @click="home()" >首页</el-button> -->
               <div id="shouyeId">
                  <input id="homeId"   type="button" value="首页🏠"  @click="home()" >
               </div>
                <div id="shengyinId">
                    <span>
                      <input id="homeId"   type="button" :value="this.shengying"  @click="shengyingClick()" :hidden="this.shengyingHidden" >
                      <input id="homeId"  type="button" @click="refenMusic()" :hidden="this.shengyingHidden" value="🔄">
                    </span>
                </div>
                
              
            </div>
            <div style="float:right;width: 20%" id="div3">
                <span :hidden="this.span1">{{this.userName}}</span>
                <!-- <el-button type="primary" icon="el-icon-s-platform" @click="denglu()" :hidden="this.input1">微信登录</el-button> -->
                <input id="wxId"   type="button" value="微信登录"  @click="denglu()" :hidden="this.input1"> 
                <input id="fkId"  type="button" value="访客登录" @click="fangke()" :hidden="this.input2">
                <!-- <el-avatar  :v-if="this.img1" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
                <img id="imgId" @click="changeUser()"  :src="this.userImage"   :hidden="this.img1" alt="">
                <!-- <img :src="'data:image/jpeg;base64,'+this.imgS" alt=""> https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png-->
            </div>
          </div>
  <div>
      <router-view></router-view>

     
    
  </div>
  <div class="mask" v-if="showModal" @click="showModal=false"></div>
  <div class="pop" v-if="showModal">
      <vue-qr  
      :text="this.text" 
      :size="350"
      :logoScale="0.2" 
      :logoSrc="this.logSrc"
      >
      </vue-qr>

  </div>

  <!-- <aPlayer :songInfo="songInfo"/> -->
</template>   
  


<script >
// import aPlayer from '@/components/aPlayer.vue'
import { jsonp } from 'vue-jsonp';
import {Howl, Howler} from 'howler';    
import axios from 'axios';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import globalPub from '@/assets/global_variable'





// import LoveVue from './views/Love.vue';
  
export default {
  created(){
      this.listenAdmin();
  
      
    
  },
  mounted(){

  },
  
  components:{
    vueQr,
    // aPlayer
  },
  data() {
    return{
      soundBgm:'',
      musicIndex:0,
      musicList:["http://music.163.com/song/media/outer/url?id=1946993993.mp3",
      "http://ws.stream.qqmusic.qq.com/C400001LcPEv1TEenh.m4a?guid=603799497&vkey=C41F9DF015B2428673E06FA8A0D8F8BDBD37437D1497708458AE4F0B498B9C42B09EB9CB80F6389754965255D36E81CA71D1B90002455B6D&uin=&fromtag=120032&src=C4000049sA4r494sZH.m4a",
      "http://ws.stream.qqmusic.qq.com/C400003PACnl0c1Obp.m4a?guid=798177759&vkey=F77001FD1B54CB3D67D6FB3B5676C9BCD1FB868D05E7D3181C4E88731B56B13B8E65E9756A337247B27F17C57E3C0289574D8383CC038040&uin=&fromtag=120032&src=C400001AcZwj2LSjG8.m4a",
      ],
      muted:false,
      shengyingHidden:true,
      shengying:'🔊',
      musics:'',
      logSrc:require('./assets/logo.png'),
      text:'',
      userName:'欢迎',
      span1:true,
      userImage:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      showModal: false,
      // imgS:'',
      img1:true,
      input1:false,
      input2:false,
      // visitorId:'',
      isHidden:'flase',
      love:'love',
      imgUrl:require('./assets/love.jpg'),//require('./assets/loveImage.gif')
      flagIsAdmin:0,
      ip: '1.1.1.1',
      area: '北京市',
      brower: 'chrome',
      os: 'windows7',
    } 
    
  },
 
 

  methods:{
    refenMusic(){
      if(this.musicIndex===this.musicList.length-1){
        this.musicIndex=0;
        // this.soundBgm.stop();
        this.music();
      }else{
        this.musicIndex=this.musicIndex+1;
        // this.soundBgm.stop();
        this.music();
      }
      
    },
    audioEnded(){
            if(this.muted===true){
                this.soundBgm.stop();
            }else{
                  this.soundBgm.stop();
                  this.soundBgm = new Howl({
                    src:[this.musicList[0]],
                    loop: true,
                    preload: true,
                    muted:this.muted,
                    html5:true
                  })
                  this.soundBgm.on('load',()=>{
                    window.WeixinJSBridge && window.WeixinJSBridge.invoke('getNetworkType', {},  ()=> {
                      this.soundBgm.play();
                        }, false);
              })
            }
            
        //   }, false);
        // })
    },
    shengyingClick(){
      if(this.shengying==="🔊"){
        this.shengying="🔇"
        this.muted=true;
        // this.audioEnded();
      }else{
        this.shengying="🔊"
        this.muted=false;
        // this.audioEnded();
      }
    },
    music(){
      
      //     const soundBgm = new Howl({
      //               src:[this.musicList[this.musicIndex]],
      //               loop: true,
      //               preload: true,
      //               muted:this.muted,
      //               html5:true
      //     })
      //   this.soundBgm=soundBgm;
      //    this.soundBgm.stop();
      //   // alert(this.soundBgm)cx
      // // 音频资源 load 之后通过微信桥接触发播放
      //   this.soundBgm.on('load',()=>{
      //     window.WeixinJSBridge && window.WeixinJSBridge.invoke('getNetworkType', {},  ()=> {
            
      //       this.soundBgm.play();
      //     }, false);
      //   })
      this.musics= this.musicList[this.musicIndex];
      var elementAudio = document.getElementById('bgmusic');

            if (elementAudio.paused) { //判读是否播放

                    this.muted=false;
                    this.shengyingHidden=false;
                    this.shengying="🔊"
                // })
            }
            else{
              //  document.addEventListener('touchstart', function() {
                 elementAudio.play(); //没有就播放
                    this.muted=false;
                    this.shengyingHidden=false;
                    this.shengying="🔊"
                // })
                
               
            }
    },
    listenAdmin(){
         window.timer = setInterval(()=>{
        console.log(globalPub.falgAdmin);
      if(globalPub.falgAdmin===0){
        this.input1=false;
        this.input2=false;
        this.xinxi=true;
        this.map=true;
      }else if(globalPub.falgAdmin===1){
        this.input1=true;
        this.input2=true;
        this.xinxi=false;
        this.map=false;
        this.userImage=globalPub.userImage;
        this.userName=globalPub.userName;
      }else if(globalPub.falgAdmin===2){
        this.xinxi=false;
        this.input2=true;
        this.input1=true;
        this.map=false;
        this.span1=false;
        this.img1=false;
        this.userImage=globalPub.userImage;
        this.userName=globalPub.userName;
      }
    },1)
    },
    home(){
       this.$router.replace({ path:'/' })
    },
    getUser(){
      
      if(window.location.href.length==="http://192.168.1.15:8080/#/".length||window.location.href.length==="http://localhost:8080/#/".length){
        this.input1=false;
        this.input2=false;
        this.img1=true;
      }else{
          // alert(window.location.href);
          // alert(decodeURIComponent(window.location.href));
          this.span1=false;
          this.input1=true;
          this.input2=true;
          this.img1=false;
          this.userImage=decodeURIComponent(window.location.href).substring(25,decodeURIComponent(window.location.href).length).split("/_")[1].substring(0,decodeURIComponent(window.location.href).substring(25,decodeURIComponent(window.location.href).length).split("/_")[1].length-2);
          this.userName=decodeURIComponent(window.location.href).substring(26,decodeURIComponent(window.location.href).length).split("/_")[0];
          // alert(this.userImage);
          // alert(this.userName);
          this.$message({
          message: '欢迎您：'+this.userName,
          type: 'success'
        });
          console.log(this.userImage);
          console.log(this.userName);
          // console.log(window.location.href.substring(22,window.location.href.length).split("/_")[0]);
          // console.log(window.location.href.substring(22,window.location.href.length).split("/_")[1]);
          // console.log(window.location.href.substring(22,window.location.href.length).split("/_")[1].substring(0,window.location.href.substring(22,window.location.href.length).split("/_")[1].length-1));
      }
      // console.log(window.location.href);
      // console.log(window.location.href.substring(22,window.location.href.length));
      // console.log(window.location.href.substring(22,window.location.href.length).split("/"))
    },
    // listen(){
    //     axios.post("/api/wechat/listen").then(response=>{
    //       console.log(response.data.openid);
    //       if(response.data.openid!=null ){
    //         globalPub.falgAdmin=2;
    //         globalPub.userImage=response.data.headimgurl;
    //         globalPub.userName=response.data.nickname;
    //         this.input1=true;
    //         this.input2=true;
    //         this.span1=false;
    //         this.showModal=false;
    //         this.img1=false;
    //         this.span1=false;
    //         this.userImage=response.data.headimgurl
    //         this.userName=response.data.nickname
    //         globalPub.openId=response.data.openid;
    //         this.$message({
    //           message: '欢迎您：'+this.userName,
    //           type: 'success'
    //         });
    //         clearInterval(window.timer);
            
    //       }else if( this.showModal===false){
    //         clearInterval(window.timer);
    //       }
    //     })
    // },
    changeUser(){
      this.soundBgm.stop();
      globalPub.falgAdmin=0;
      this.muted=true;
      this.shengyingHidden=true;
      this.input1=false;
      this.input2=false;
      this.span1=true;
      this.img1=true;
      this.$router.replace("/")
    },
    getWxeweima(){
       axios.post("/api/wechat/wxLogin").then(response=>{
         
          this.text=response.data;
          this.showModal=true;
          console.log(response.data);
       
        })
    },

    fangke(){
      this.music();
      //  alert("访客")
       this.userImage="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png";
       this.userName="欢迎";
       globalPub.falgAdmin=1;//访客登录，只读权限
       this.span1=false;
       this.input1=true; //登录框隐藏
       this.input2=true;//登录框2隐藏
       this.img1=false;//游客头像框显示
       globalPub.userImage=this.userImage;
       globalPub.userName=this.userName;
      //  this.$router.replace({ path:'/' })

       
       
    },
    denglu(){
      
      
      
      
      this.getWxeweima();
      // if(this.showModal===true){
      //   //每隔一秒钟监听是否扫描二维码拿到用户openid和其他数据
      //   window.timer = setInterval(()=>{
      //     this.listen();
      //   },1000)
      // }
     
   
   
       
    },
    love_image_true(){

      if(this.flagIsAdmin===2){
        if(this.isHidden==='flase'){
          this.$router.replace("/love_image")
          this.isHidden='true'
          this.love='image'
          this.imgUrl=require('./assets/tourism.jpg')
        }else if(this.isHidden==='true'){
          this.$router.replace("/love_other")
          this.isHidden='flase'
          this.love='love'
          this.imgUrl=require('./assets/loveImage.gif')
        }
      }else{
        if(this.isHidden==='flase'){
          this.$router.replace("/love_image")
          this.isHidden='true'
          this.love='image'
          this.imgUrl=require('./assets/tourism.jpg')
        }else if(this.isHidden==='true'){
          this.$router.replace("/")
          this.isHidden='flase'
          this.love='love'
          this.imgUrl=require('./assets/love.jpg')
        }
      }
   
    },
    getUserIpConfig() {
    // alert(window.location.href);
	return new Promise((resolve) => {
    // this.ip = sessionStorage.getItem('ip')
    // this.area = sessionStorage.getItem('area')
    // this.brower = sysTool.GetCurrentBrowser()
    // this.os = sysTool.GetOs()
    // // alert(this.ip);
    // console.log(sysTool)
    // console.log(sessionStorage)
    // console.log('ip,地区,浏览器,操作系统,:',  this.ip, this.area,this.brower, this.os)
    
		jsonp(`https://apis.map.qq.com/ws/location/v1/ip?key=6B2BZ-XMUKZ-BRZXF-TBP5H-2DDWK-HLF7J`, {
			output: 'jsonp'
		}).then(resp => {
      // var us = JSON.parse(window.localStorage.getItem("user"));
      // console.log(us);
      // alert(resp.result.ip)
      // console.log(resp);
      // alert(resp.result.ip);
      // alert(this.visitorId);
      if(!(resp.result.ip==='2409:8962:b53:437c:c50:7715:facb:8dae'||resp.result.ip==='183.221.14.227')){ //侯涛手机ip地址
        this.flagIsAdmin=2; 
        this.$router.replace("/love_other");
        this.isHidden='true';
        this.love='welcome';
        this.imgUrl=require('./assets/loveImage.gif');
      }else{
        this.flagIsAdmin=0;
        this.$router.replace("/");
        this.isHidden='flase';
        this.love='love';
        this.imgUrl=require('./assets/love.jpg');
      }
      
      // console.log(resp.result.ip)
			resolve(resp);
		}).catch(err => {
			console.log(err);
		})
	})
  }
  }
  
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// .love_image_icon{
//     background-image: url('./assets/welcome.png');
// }
// .love_home_icon{
//     background-image: url('./assets/welcome.png');
// }
// .love_other_icon{
//     background-image: url('./assets/welcome.png');
// }
// .icon{
//     width: 50px;
//     height: 50px;
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-size: contain;
// }
#shengyinId{
  margin-top: 23px;
}
#image_icon{
  background-image: url('./assets/love.jpg');
  background-color: rgb(255, 208, 0);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  padding: 0px;
  width: 100%;
  height: 11%;
  top: 0;
  left: 0; 
}
#imgId{
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  padding: 0px;
  width: 10%;
  height: 60%;
  top: 15px;
  left: 85%;
}
.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.pop {
  background-color: #fff;
  text-align: center;
  position: fixed; 
  top: 100px;
  float: left ;
  padding-left: 0px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  /* width: 300px;
  height: 400px; */
  z-index: 2
}
#wxId{
  background-color: rgb(118, 93, 62);
  color: #fff;
}
#homeId{
  background-color: rgb(118, 93, 62);
  color: #fff;
}
#fkId{
  background-color: rgb(118, 93, 62);
  color: #fff;
}
</style>
